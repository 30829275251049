
.q-img {
	display: block;
	margin: 30px auto;
	width: 100%;

	&::v-deep .q-img__content {
		> div {
			background: none;
		}
	}
	&.res-vul {
		width: 50%;
		max-width: 300px;
	}
	&.action {
		max-width: 600px;
		cursor: pointer;
	}
}
